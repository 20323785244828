<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="text-center">Briefing - Content Based Website</h1>
        <v-card>
          <v-card-title>
            {{ briefing[0].name }}
          </v-card-title>
          <v-card-subtitle>
            {{ briefing[0].company }}
          </v-card-subtitle>
          <v-card-text>
            Mobile: {{ briefing[0].phone }}
            <br>Email: {{ briefing[0].email }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <h2 class="mt-10 mb-0">Overview</h2>
    <h3 class="mt-5 mb-1">What do we need to do?</h3>
    <p>What are we creating? What is the purpose of this website?</p>
    <v-textarea
      v-model="details"
      label="What do we need to do?"
      outlined
    />
    <h3 class="mt-5 mb-1">Reference</h3>
    <p>Website references of what you or the client likes / dislikes. Please include job reference details if referring to work we have previously completed.</p>
    <v-textarea
      v-model="references"
      label="References"
      outlined
    />
    <h3 class="mt-5 mb-1">What should we consider?</h3>
    <p>Who is the target market, audiences, customers?</p>
    <v-textarea
      v-model="considerations"
      label="What should we consider?"
      outlined
    />
    <h3 class="mt-5 mb-1">Mandatories</h3>
    <p>Retail specific requirements. Must do//Must nots. Please also include any content / form submission fields and copy if required in this section.</p>
    <v-textarea
      v-model="mandatories"
      label="Mandatories"
      outlined
    />
    <h3 class="mt-5 mb-1">What is expected/required?</h3>
    <p>Timelines/deadlines, testing, outputs, etc.</p>
    <v-textarea
      v-model="requirements"
      label="What is expected/required?"
      outlined
    />
    <h2 class="mt-10 mb-0">Digital</h2>
    <h3 class="mt-5 mb-1">Domain Name (URL)</h3>
    <p>What is the URL this website is going to be on?  If it doesn’t exist yet, are we purchasing it? Please include details</p>
    <v-textarea
      v-model="domain"
      label="Domain Name & Details"
      outlined
    />
    <h3 class="mt-5 mb-1">Webhosting</h3>
    <p>Does this website have hosting or are we hosting it on our server. Please include details.</p>
    <v-textarea
      v-model="domain"
      label="Webhosting Details"
      outlined
    />
    <h3 class="mt-5 mb-1">CMS (Content Management System)</h3>
    <p>Do the clients need to update the site themselves?  If yes, list out what they would want to do on their end. Do they have a CMS preference?</p>
    <v-textarea
      v-model="cms"
      label="CMS Details"
      outlined
    />
    <h3 class="mt-5 mb-1">Navigations</h3>
    <p>Briefly map out the pages and how they sit within the site. E.g. Main Page, About Us Page, History, Contacts Page</p>
    <v-textarea
      v-model="navigation"
      label="Navigation Details"
      outlined
    />
    <h3 class="mt-5 mb-1">What functions is expected/required?</h3>
    <p>Are there specific functions we’ll need to build? E.g. Contact forms, shop page, subscriptions, etc. </p>
    <v-textarea
      v-model="functions"
      label="What functions is expected/required?"
      outlined
    />
    <h3 class="mt-5 mb-0">Wireframes</h3>
    <p>Have wireframes / flatlays already been designed up? If so please provide files and tell us more below. If you need us to design from scratch, please include any brand guidelines / layered files if you have any available + any design guidance you may wish to include in the field below.</p>
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <v-textarea
          label="Tell us more ..."
          outlined
          hide-details="auto"
        />
      </v-col>
      <v-col
        cols="12"
        sm="1"
        class="d-flex justify-center align-center"
      >
        <strong>OR</strong>
      </v-col>
      <v-col
        cols="12"
        sm="5"
      >
        <vue-dropzone id="dropzone" ref="myVueDropzone" :options="dropzoneOptions" :use-custom-slot="true">
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">Drop file here or click to upload</h3>
            <div class="subtitle" />
          </div>
        </vue-dropzone>
      </v-col>
    </v-row>
    <br>
    <center><v-btn
      color="primary"
      class="px-10"
      large
      to="confirmation"
      @click.native="scrollToTop()"
    >
      Submit
    </v-btn></center>
  </v-container>
</template>

<script>
import { briefingData } from '@/data/briefingData'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default ({
  components: {
    vueDropzone: vue2Dropzone
  },
  data() {
    return {
      briefing: briefingData,
      details: '',
      references: '',
      considerations: '',
      mandatories: '',
      requirements: '',
      domain: '',
      webhosting: '',
      cms: '',
      navigation: '',
      functions: '',
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { 'My-Awesome-Header': 'header value' }
      }
    }
  }
})
</script>

<style lang="scss">
p {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
  color: rgba(0, 0, 0, 0.6);
}
.vue-dropzone {
  border: 1px solid rgba(0,0,0,0.38);
  border-radius: 4px;
  &:hover {
    border-color: rgba(0,0,0,1);
  }
  &:focus, &:active {
    border-width: 2px;
    border-color: #EC088C;
  }
}
.dropzone .dz-message {
  margin: 1.25rem 0;
}
</style>
